/* after changing this file run 'npm run webapp:build' */

/***************************
put Sass variables here:
eg $input-color: red;
****************************/
@import 'bootswatch/dist/minty/variables';
// Override Bootstrap variables
@import 'bootstrap-variables';
// Import Bootstrap source files from node_modules
@import 'bootstrap/scss/bootstrap';
@import 'bootswatch/dist/minty/bootswatch';

/* jhipster-needle-scss-add-vendor JHipster will add new css style */
