@import 'bootstrap-variables';
@import 'bootstrap/scss/functions';
@import 'bootswatch/dist/minty/variables';
@import 'bootstrap/scss/variables';

/* ==============================================================
Bootstrap tweaks
===============================================================*/
.navbar {
  position: fixed;
  top: 0;
  /* Position at the top of the viewport */
  left: 0;
  /* Position at the left of the viewport */
  width: 100vw;
  /* Span the full width */
  z-index: 10;
}

button:hover {
  background-color: #0078c6;
}

body,
h1,
h2,
h3,
h4 {
  font-weight: 300;
}

h1,
h2 {
  font-family: 'ClashDisplay-Semibold';
}

a,
span,
label {
  font-family: 'ClashDisplay-Regular';
}

.form-label {
  font-family: 'ClashDisplay-Regular';
  color: #5a5d64;
}

.form-control {
  font-family: 'ClashDisplay-Regular';
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d1d5db;
  border-radius: 7px;
  height: 60px;
}

form {
  @media (max-height: 800px) {
    .mb-3 {
      margin-bottom: 0.5rem !important;
    }
  }
}

a:hover {
  /* make sure browsers use the pointer cursor for anchors, even with no href */
  cursor: pointer;
}

.dropdown-item:hover {
  color: $dropdown-link-hover-color;
}

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */
.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

/* ==========================================================================
Generic styles
========================================================================== */

/* other generic styles */

.jh-card {
  padding: 4.5%;
  margin-top: 20px;
  border: none;
}

.error {
  color: white;
  background-color: red;
}

.pad {
  padding: 10px;
}

.w-40 {
  width: 40% !important;
}

.w-60 {
  width: 60% !important;
}

.break {
  white-space: normal;
  word-break: break-all;
}

.form-control {
  background-color: #fff;
}

.readonly {
  background-color: #eee;
  opacity: 1;
}

.footer {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  .description {
    padding-right: 65px;
  }
  .footer-title {
    margin-top: 58px;
  }
  .footer-bottom {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 5px;
  }
}

.hand,
[jhisortby] {
  cursor: pointer;
}

/* ==========================================================================
Custom alerts for notification
========================================================================== */
.alerts {
  .alert {
    text-overflow: ellipsis;
    position: fixed;
    right: 20px !important;
    top: 20px !important;
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
    width: auto;
    /* Adjust width as needed */
    background-color: #343a40;
    /* Default background color */
    color: #fff;
    /* Default text color */
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);

    &.success {
      background-color: #28a745;
      /* Success background color */
    }

    &.error {
      background-color: #dc3545;
      /* Error background color */
    }
  }

  .jhi-toast {
    position: fixed;
    width: auto;
    max-width: 300px;
    /* Adjust max-width as needed */
    left: 50%;
    transform: translateX(-50%);
    z-index: 9999;

    /* Ensure toast appears on top of other elements */
    &.top {
      top: 20px;
    }

    &.bottom {
      bottom: 20px;
    }
  }
}

@media screen and (min-width: 480px) {
  .alerts .jhi-toast {
    width: auto;
    max-width: 50%;
    /* Adjust max-width as needed */
    left: auto;
    right: 20px;
    /* Adjust right offset as needed */
    transform: none;
  }
}

/* ==========================================================================
entity list page css
========================================================================== */

.table-entities thead th .d-flex > * {
  margin: auto 0;
}

/* ==========================================================================
entity detail page css
========================================================================== */
.row-md.jh-entity-details {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 10px;
  line-height: 1.5;
}

@media screen and (min-width: 768px) {
  .row-md.jh-entity-details > {
    dt {
      float: left;
      overflow: hidden;
      clear: left;
      text-align: right;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0.5em 0;
    }

    dd {
      border-bottom: 1px solid #eee;
      padding: 0.5em 0;
      margin-left: 0;
    }
  }
}

/* ==========================================================================
ui bootstrap tweaks
========================================================================== */
.nav,
.pagination,
.carousel,
.panel-title a {
  cursor: pointer;
}

.thread-dump-modal-lock {
  max-width: 450px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dropdown-menu {
  padding-left: 0px;
}

/* ==========================================================================
angular-cli removes postcss-rtl processed inline css, processed rules must be added here instead
========================================================================== */
/* page-ribbon.component.scss */
.ribbon {
  left: -3.5em;
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

/* navbar.component.scss */
.navbar {
  ul.navbar-nav {
    .nav-item {
      margin-left: 0.5em;
    }
  }
}

.btn.success-btn:hover {
  background: #0078c6;
  color: #ffffff;
}

/* jhipster-needle-scss-add-main JHipster will add new css style */
@font-face {
  font-family: 'ClashDisplay-Semibold';
  src:
    url('../fonts/ClashDisplay-Semibold.woff2') format('woff2'),
    url('../fonts/ClashDisplay-Semibold.woff') format('woff'),
    url('../fonts/ClashDisplay-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'ClashDisplay-Regular';
  src:
    url('../fonts/ClashDisplay-Regular.woff2') format('woff2'),
    url('../fonts/ClashDisplay-Regular.woff') format('woff'),
    url('../fonts/ClashDisplay-Regular.ttf') format('truetype');
  // font-weight: 600;
  // font-display: swap;
  // font-style: normal;
}

@font-face {
  font-family: 'ClashDisplay-Medium';
  src:
    url('../fonts/ClashDisplay-Medium.woff2') format('woff2'),
    url('../fonts/ClashDisplay-Medium.woff') format('woff'),
    url('../fonts/ClashDisplay-Medium.ttf') format('truetype');
  // font-weight: 600;
  // font-display: swap;
  // font-style: normal;
}

.registerLogin {
  @media (max-height: 800px) {
    .form-control {
      height: 45px;
    }
  }
  h1 {
    text-align: left;
    font: normal normal 600 36px/44px Clash Display;
    letter-spacing: 0.6px;
    color: #0078c6;
    opacity: 1;
    font-family: 'ClashDisplay-Semibold';
    margin: 0rem 0 3rem;
  }
  @media (max-height: 800px) {
    padding: 0rem 1rem;
    h1 {
      margin-bottom: 0rem;
      font-size: 26px;
    }
  }
  display: flex;
  flex-direction: column;
  // padding: 3rem 6rem;
  padding: 4rem 2rem;
  margin-left: 2.5rem;

  // padding: 0rem 3rem;
  @media (max-width: 1300px) {
    width: 100%;
    margin: 0rem;
    padding: 1rem 4rem;
  }
}

.logo-pharma {
  width: 185px;
  height: 35px;
  margin-right: 30px;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  background: transparent url('/content/images/Logo.svg') 0% 0% no-repeat padding-box;
  background-size: contain;
  font-family: 'ClashDisplay-Semibold';
}

.logo-pharma-dark {
  width: 185px;
  height: 35px;
  margin: 2rem 0rem;
  display: inline-block;
  vertical-align: middle;
  background: transparent url('/content/images/logo-dark.png') 0% 0% no-repeat padding-box;
  background-size: contain;
  font-family: 'ClashDisplay-Semibold';
}

.success-btn {
  background: #83b011 0% 0% no-repeat padding-box;
  border-radius: 7px;
  opacity: 1;
  height: 60px;
  text-align: center;
  font: normal normal 600 16px/14px;
  letter-spacing: 0px;
  color: #ffffff;
  font-family: 'ClashDisplay-Semibold';
  border-color: #83b011;
}

.login-register-image {
  // width: calc(100%);
  // height: calc(100%);
  // padding-left: 6rem;
  // width: calc(62%);
  // height: calc(100%);
  // position: fixed;
  // right: 0;
  width: 62%;
  height: 100%;
  position: fixed;
  right: 0;
  object-fit: cover;
  border-radius: 2rem 0rem 0rem 2rem;
  transform: scale(1);
}

.loginClass {
  margin-top: 4rem;
  /* height: 100%; */
  margin-left: 2rem;
  padding: 3rem;
}

@media (max-width: 1300px) {
  .login-register-image {
    display: none;
  }
  .col-log-register {
    display: none;
  }

  .registerLogin {
    display: flex;
    flex-direction: column;
    // align-items: center;
    // margin-left: unset;
    width: calc(100%);
    // margin: 5rem;

    .header {
      @media (max-width: 500px) {
        flex-direction: column-reverse;
      }
    }
  }
}

// .col-log-register {
//   margin: -3rem -2.9rem -12.5rem 0rem;
// }

.alert-link {
  color: #0078c6;
}

.location-icon {
  width: 22px;
  height: 24px;
  background: transparent url('../images/map-pin.svg') 0% 0% no-repeat padding-box;
  display: flex;
  align-self: center;
  margin: 0.5rem;
}

.avatar-icon {
  width: 27px;
  height: 25px;
  background: transparent url('../images/feather-user.svg') 0% 0% no-repeat padding-box;
  display: flex;
  align-self: center;
  margin: 0.5rem;
}

.my-container {
  max-width: 960px;
  /* Adjust as needed */
  margin: 0 auto;
  /* Center the container horizontally */
  padding: 1rem;
}

.search-icon {
  /* UI Properties */
  background: transparent url('./../images/feather-search.svg') 0% 0% no-repeat padding-box;
  // left: 62rem;
  width: 16px;
  height: 16px;
  display: flex;
  align-content: stretch;
  /* align-content: stretch; */
  justify-content: flex-end;
  float: right;
  margin: -2rem 1rem;
}

.nav-input {
  background: #f3f4f6 0% 0% no-repeat padding-box;
  border-radius: 7px;
  height: 46px;
  border: none;
  padding-right: 38px;
}

.icon {
  width: 18px;
  height: 18px;
}

.footer {
  color: #ffffff;
  // height: 306px;
  background: #141414 0% 0% no-repeat padding-box;
  @media (max-width: 991px) {
    height: unset;

    .description,
    .links,
    .contacts {
      width: 100%;
    }
  }

  li {
    font-family: 'ClashDisplay-Medium';
  }

  p {
    font-family: 'ClashDisplay-Medium';
  }

  span {
    font-size: 14px;
    text-align: center;

    .pharma {
      color: #83b011;
    }

    .underline {
      color: #0078c6;
      text-decoration: underline;
    }
  }

  .nav-item {
    margin-right: 1rem;
  }

  h4 {
    font-family: 'ClashDisplay-Semibold';
    font-size: 18px;
    color: #83b011;
  }

  .links,
  .contacts {
    @media (max-width: 991px) {
      margin-top: 2rem;
    }

    ul {
      list-style-type: disc;
      padding: 0rem 1rem;

      @media (max-width: 991px) {
        flex-direction: column;
      }

      li {
        margin-bottom: 0.5rem;
        a {
          color: #fff !important;
          text-decoration: none;
        }
      }
    }
  }

  .contacts {
    ul {
      padding: 0rem;
      list-style: none;
      margin-left: -1rem;

      li {
        margin-bottom: 0.5rem;
        display: flex;

        &::before {
          display: flex;
          justify-content: center;
          width: 12%;
          margin-right: 0.5rem;
        }

        &.email::before {
          content: '';
          background: url('../../content/images/icons/email.svg') no-repeat;
          background-position: center 3px;
        }

        &.phone::before {
          content: url(../../content/images/icons/phone.svg);
        }

        &.address::before {
          content: url(../../content/images/icons/location.svg);
          padding: 0rem 1rem;
        }
      }
    }
  }

  hr {
    margin: 1rem 0rem 0.5rem 0rem;
  }
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: white;
  background-color: #0078c6;
}

a.navbar-toggler {
  color: black;
}

.navbar .navbar-toggler:hover {
  color: rgba(1, 1, 1, 0.75);
}

.text-register-client {
  position: absolute;
  bottom: 3px;
  display: flex;
  /* flex-direction: column; */
  padding: 2rem 2rem;

  h3 {
    /* position: absolute; */
    z-index: 99999;
    /* bottom: 36px; */
    text-align: center;
    font: normal normal normal 18px / 23px Clash Display;
    letter-spacing: 0px;
    color: #ffffff;
    right: 1rem;
    text-wrap: wrap;
    font-family: 'ClashDisplay-Regular';
    font-size: 18px;
  }
}

.inline-inputs {
  display: flex;
  margin-top: 2rem;
  margin: auto;
}

.small-input {
  width: 60px;
  height: 60px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d1d5db;
  border-radius: 7px;
  margin: auto;
  text-align: center;
}

.small-input:focus {
  outline: none;
  border-color: #3498db;
  /* Change border color when input is focused */
}

/* Optional: Add styles for input hover state */
.small-input:hover {
  background-color: #f2f2f2;
}

.otpText {
  text-align: left;
  // font: normal normal normal 16px/22px Clash Display;
  letter-spacing: 0px;
  color: #5a5d64;
  font-family: 'ClashDisplay-Regular';
  font-size: 16px;
}
.modal-dialog.modal-dialog-centered.modal-l {
  max-width: 601px !important;
}
.modal-content {
  border-radius: 16px;
}

.flag-map-icon {
  /* UI Properties */
  background: transparent url('./../images/icons/flag.svg') 0% 0% no-repeat padding-box;
  // left: 62rem;
  width: 16px;
  height: 20px;
  display: flex;
  align-content: stretch;
  /* align-content: stretch; */
  justify-content: flex-end;
  float: left;
  margin: -2.5rem 1rem;
}
.form-check-input[type='checkbox'] {
  border-radius: 0.25em;
  border: 1px solid #d1d5db;
  height: 24px;
  width: 24px;
  margin-right: 10px;
  margin-top: 0;
}
.form-check-input:checked {
  background-color: #83b011;
  border-color: #83b011;
}
.sticky-nav {
  position: sticky;
  top: 128px;
}

img {
  max-width: 100%;
}

.card-vertical-nav {
  border: 1px solid #e5e7eb;
  border-bottom: 0;
  overflow: hidden;
  @media only screen and (max-width: 1199px) {
    width: fit-content;
    position: fixed;
    left: 0;
    top: 150px;
    max-width: unset;
    min-width: unset;
    li {
      span {
        display: none;
      }
      &:hover {
        width: unset;
        span {
          display: unset;
        }
      }
    }
  }
  border: 1px solid #e5e7eb;
  border-radius: 12px;
  li {
    border-bottom: 1px solid #e5e7eb;
    padding: 0.5rem 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;

    &.nav-item::before {
      // margin-right: 1rem;
      display: flex;
      width: 15%;
      justify-content: center;
    }

    &.pharmacies::before {
      content: url(../../content/images/icons/pharmacies.svg);
    }

    &.products::before {
      content: url(../../content/images/icons/products.svg);
    }

    &.panier::before {
      content: url(../../content/images/icons/panier.svg);
    }

    &.orders::before {
      content: url(../../content/images/icons/orders.svg);
    }

    &.favoris::before {
      content: url(../../content/images/icons/fav.svg);
    }

    &.payments::before {
      content: url(../../content/images/icons/pay.svg);
    }

    &.account::before {
      content: url(../../content/images/icons/account.svg);
    }

    &.logout::before {
      content: url(../../content/images/icons/logout.svg);
    }

    span {
      font-family: 'ClashDisplay-Medium';
      font-size: 18px;
    }
  }

  li.active {
    background: #83b011 0% 0% no-repeat padding-box;
    color: #ffffff;

    &.pharmacies::before {
      content: url(../../content/images/icons/phar-wh.svg);
    }

    &.panier::before {
      content: url(../../content/images/icons/panier-white.svg);
    }

    &.products::before {
      content: url(../../content/images/icons/products-wh.svg);
    }

    &.orders::before {
      content: url(../../content/images/icons/orders-wh.svg);
    }

    &.favoris::before {
      content: url(../../content/images/icons/fav-wh.svg);
    }

    &.payments::before {
      content: url(../../content/images/icons/pay-wh.svg);
    }

    &.account::before {
      content: url(../../content/images/icons/account-wh.svg);
    }

    &.logout::before {
      content: url(../../content/images/icons/logout-wh.svg);
    }
  }
}
/* Customize scrollbar */
.overflow-x-scroll::-webkit-scrollbar {
  width: 12px;
}

.overflow-x-scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.overflow-x-scroll::-webkit-scrollbar-thumb {
  background-color: #0078c6;
  border-radius: 6px;
}

.overflow-x-scroll::-webkit-scrollbar-thumb:hover {
  background: #83b011;
}
.overflow-x-scroll::-webkit-scrollbar-button {
  display: block;
}

.alert-link {
  font-weight: normal;
}

.btn-connect-header {
  margin: 0 16px 0 40px;
  width: 160px;
  max-width: 45%;
}

.btn-register-header {
  margin-left: 0 !important;
  width: 141px;
  max-width: 38%;
}
.navbar-toggler:focus {
  box-shadow: none !important;
}

ul#strengthBar {
  padding-left: 0;
}

.ng-otp-input-wrapper {
  width: 343px;
  max-width: 100%;
  margin: 0 5%;
}

.form-label {
  margin: 20px 0 10px;
  display: inline-block;
}

.mr-0 {
  margin-right: 0 !important;
}

@media screen and (max-width: 1199px) {
  div#navbarResponsivee .nav-item {
    margin-left: 20px;
  }
  .btn-connect-header {
    margin-left: 0 !important;
  }
}
@media screen and (max-width: 768px) {
  .registerLogin h1 {
    font-size: 30px;
  }
  .registerLogin {
    padding: 1rem 2rem;
  }
  nav.navbar.navbar-dark.navbar-expand-md.custom-up-nav {
    display: none;
  }
}
